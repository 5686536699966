<template>
  <div class="sharedResortsList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(sharedResorts)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(sharedResorts)"
        :sortable="{
          allowUnsort: true,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <div
          slot="idFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="idFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a id"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('idFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <div
          slot="sharedResortIdFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="sharedResortIdInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a id"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('sharedResortIdInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <div
          slot="sharedResortNameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="sharedResortNameInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('sharedResortNameInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="showSidebarEdit(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span class="hide-down-sm">{{ $t('edit') }}</span>
              </button>
              <button
                slot="button"
                class="btn btn-danger btn-sm"
                @click="openConfirmModal(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="trash"
                />
                <span class="hide-down-sm">{{ $t('remove') }}</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <SharedResortAdd
          v-if="addingElement"
          :resort-id="resortId"
          @reload="reloadSharedResorts"
        />
        <SharedResortEdit
          v-else
          :shared-resort="sharedResort"
          @reload="reloadSharedResorts"
        />
      </Sidebar>
    </template>
    <SweetModal
      ref="confirmDelete"
      :title="$t('introOutroListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('introOutroListComp.sureToDelete') }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeSharedResort()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t('delete') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import Sidebar from '@/components/Base/Sidebar.vue';
import SharedResortAdd from '@/components/Resort/SharedResortAdd.vue';
import SharedResortEdit from '@/components/Resort/SharedResortEdit.vue';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: "LiftList",
  components: {
    Sidebar,
    SweetModal,
    SharedResortAdd,
    SharedResortEdit
  },
  mixins: [
    kendoGridMixin
  ],
  props: {
    resortId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      sharedResorts: null,
      sharedResort: null,
      addingElement: false,
      showSidebar: false,
      deleteIndex: null,
      kgm_columns: [
        {
          field: 'id',
          filterable: true,
          title: 'Id',
          filterCell: "idFilter",
          hideOn: ['xsDown']
        },
        {
          field: 'sharedResortId',
          filterable: true,
          filter: 'text',
          title: 'Shared Resort Id',
          filterCell: "sharedResortIdFilter",
        },
        {
          field: 'sharedResort.name',
          filterable: true,
          title: 'Shared Resort Name',
          filterCell: "sharedResortNameFilter",
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
        }
      ]
    }
  },
  watch: {
    resortId () {
      this.getData();
    }
  },
  created () {
    this.getData();
  },
  methods: {
    //sidebar
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    showSidebarAdd () {
      this.addingElement = true;
      this.show();
    },
    showSidebarEdit (emitValues) {
      this.setCurrentSharedResort(emitValues);
      this.addingElement = false;
      this.show();
    },
    openConfirmModal (index) {
      this.deleteIndex = index;
      this.$refs.confirmDelete.open();
    },
    closeModal () {
      this.reloadSharedResorts();
    },
    setCurrentSharedResort (resort) {
      this.sharedResort = JSON.parse(JSON.stringify(resort));
    },
    reloadSharedResorts () {
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getData();
    },
    //#region API-calls
    getData () {
      this.loading = true;
      this.axios.get(`/Resort/GetResortSharingList?resortId=${ this.resortId }`)
      .then(response => {
        this.sharedResorts = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    },
    removeSharedResort () {
      this.axios.delete(`/Resort/RemoveSharedResort?id=${ this.deleteIndex }`)
      .then(() => {
        this.$snotify.success(this.$t('sharedResortForms.removedSuccessfullySharedResort'));
        this.reloadSharedResorts();
      })
      .finally(() => {
        this.deleteIndex = null;
        this.$refs.confirmDelete.close();
      });
    }
  }
}
</script>
<style>
.sharedResortsList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>