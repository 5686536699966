<template>
  <div class="sharedResortEdit">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <form>
        <div class="form-group">
          <h4 class="m-0 mt-3">
            {{ $t('sharedResortForms.editSharedResort') }}
          </h4>
          <hr class="m-0 mb-3">
          <label>{{ $t('menu.resort') }}</label>
          <Multiselect
            id="resort"
            v-model="sharedResort.resort"
            disabled            
            :options="resorts"
            :custom-label="customLabel"
            label="name"
            :close-on-select="true"
            class="mb-3"
          />
          <label>{{ $t('sharedResortForms.sharedResort') }}</label>
          <Multiselect
            id="sharedResorts"
            v-model="sharedResort.sharedResort"            
            :options="sharedResorts"
            :custom-label="customLabel"
            label="name"
            :close-on-select="true"
            class="mb-3"
          />
        </div>
        <button
          class="btn btn-primary float-right"
          :disabled="sharedResort == null"
          @click.prevent="editSharedResort()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="edit"
          />{{ $t('edit') }}
        </button>
        <div class="clearfix" />
      </form>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "SharedResortEdit",
  components: {
    Multiselect: () => import('vue-multiselect'),
  },
  mixins: [
    errorMixin
  ],
  props: {
    sharedResort: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      sharedResorts: null,
      resorts: null,
      loading: false
    }
  },
  created () {
    this.resorts = [ this.sharedResort.resort ];
    this.getSharedResortsSelection();
  },
  methods: {
    customLabel ({ id, name }) {
      return `${id} — ${name}`
    },
    getSharedResortsSelection () {
      this.loading = true;
      this.axios.get(`/Resort/GetSharedResortsByResortId?resortId=${ this.sharedResort.resort.id }`)
      .then(response => {
        this.sharedResorts = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    },
    async editSharedResort () {
      let postObject = {
        'id': this.sharedResort.id,
        'resortId': this.sharedResort.resort.id,
        'sharedResortId': this.sharedResort.sharedResort.id,
      }
      await this.axios.post('/Resort/UpdateSharedResort', postObject)
        .then(() => {
          this.$snotify.success(this.$t('sharedResortForms.editedSuccessfullySharedResort'));
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<style scoped>
.sharedResortEdit .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>