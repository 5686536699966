<template>
  <div class="sharedResortAdd">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <form>
        <div class="form-group">
          <h4 class="m-0 mt-3">
            {{ $t('sharedResortForms.addSharedResort') }}
          </h4>
          <hr class="m-0 mb-3">
          <label>{{ $t('menu.resort') }}</label>
          <Multiselect
            id="resort"
            v-model="resort"
            disabled            
            :options="resorts"
            :custom-label="customLabel"
            label="name"
            :close-on-select="true"
            class="mb-3"
          />
          <label>{{ $t('sharedResortForms.sharedResort') }}</label>
          <Multiselect
            id="sharedResorts"
            v-model="sharedResort"            
            :options="sharedResorts"
            :custom-label="customLabel"
            label="name"
            :close-on-select="true"
            class="mb-3"
          />
        </div>
        <button
          class="btn btn-primary float-right"
          :disabled="sharedResort == null"
          @click.prevent="addSharedResort()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="check"
          />{{ $t('add') }}
        </button>
        <div class="clearfix" />
      </form>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "SharedResortAdd",
  components: {
    Multiselect: () => import('vue-multiselect'),
  },
  mixins: [
    errorMixin
  ],
  props: {
    resortId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      sharedResorts: null,
      sharedResort: null,
      resorts: null,
      loading: false
    }
  },
  created () {
    this.getResort(this.resortId);
    this.getSharedResortsSelection();
  },
  methods: {
    customLabel ({ id, name }) {
      return `${id} — ${name}`
    },
    getResort (id) {
    this.axios.get(`/Resort/GetResort?id=` + id)
      .then((response) => {
        if (response) {
          this.resort = response.data; 
          this.resorts = [ this.resort ];
        }
      })
    },
    getSharedResortsSelection () {
      this.loading = true;
      this.axios.get(`/Resort/GetSharedResortsByResortId?resortId=${ this.resortId }`)
      .then(response => {
        this.sharedResorts = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    },
    async addSharedResort () {
      let postObject = {
        'resortId': this.resortId,
        'sharedResortId': this.sharedResort.id,
      }
      await this.axios.post('/Resort/CreateSharedResort', postObject)
        .then(() => {
          this.$snotify.success(this.$t('sharedResortForms.addedSuccessfullySharedResort'));
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<style scoped>
.sharedResortAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>